<template>
  <div>
    <PageHeader title="Modification d'un sondage" />
    <PageContent v-if="survey">
      <div class="m-auto max-w-6xl shadow sm:rounded-md">
        <ValidationObserver v-slot="{ invalid }">
          <div class="space-y-6 bg-white sm:p-6">
            <div class="grid grid-cols-4 gap-6">
              <ValidationProvider
                class="col-span-4"
                name="Question"
                rules="required|min:10|max:180"
                v-slot="{ errors }"
              >
                <t-input-group
                  label="Titre"
                  :variant="{ 'danger': errors[0] }"
                  :feedback="errors[0]"
                >
                  <t-input
                    v-model="survey.question"
                    placeholder="Question"
                    :variant="{ 'danger': errors[0] }"
                  />
                </t-input-group>
              </ValidationProvider>
              <div class="col-span-4 flex flex-col gap-5">
                <div
                  v-for="(option, i) in survey.options"
                  :key="i"
                >
                  <ValidationProvider
                    class="flex justify-between"
                    :name="`Réponse ${i + 1}`"
                    rules="required|min:3|max:180"
                    v-slot="{ errors }"
                  >
                    <t-input-group
                      class="w-full"
                      :label="`Réponse ${i + 1}`"
                      :variant="{ 'danger': errors[0] }"
                      :feedback="errors[0]"
                    >
                      <t-input
                        v-model="survey.options[i].option"
                        placeholder="Question"
                        :variant="{ 'danger': errors[0] }"
                      />
                    </t-input-group>
                    <div
                      v-if="survey.options.length > 2"
                      class="col-span-1 p-3 flex items-end justify-center cursor-pointer"
                      @click="removeOption(i)"
                    >
                      <i class="fas fa-times text-red-700" />
                    </div>
                  </ValidationProvider>
                </div>
                <div class="flex space-x-2">
                  <t-button
                    @click="addOption"
                    class="w-7/12"
                  >+ Ajouter une option</t-button>
                  <t-button
                    @click="addNoReplyOption"
                    class="w-5/12"
                    :disabled="noReplyAdded"
                  >+ Ajouter option 'ne souhaite pas répondre'</t-button>
                </div>
                <div class="col-span-4">
                  <t-input-group label="Possibilité de changer de réponse">
                    <t-toggle v-model="survey.is_reanswerable" />
                  </t-input-group>
                </div>
                <t-input-group
                  label="Groupes (facultatif)"
                  v-if="usersGroups && usersGroups.length"
                >
                  <t-rich-select
                    multiple
                    :close-on-select="false"
                    :options="usersGroups"
                    placeholder="Sélectionner des groupes"
                    v-model="groupsSelected"
                  ></t-rich-select>
                  <!-- {{ groupsSelected }} -->
                </t-input-group>

              </div>
              <ValidationProvider
                class="col-span-2"
                name="Début du sondage"
                :rules="dateFieldRules"
                v-slot="{ errors }"
              >
                <t-input-group
                  label="Début du sondage"
                  :variant="{ 'danger': errors[0] }"
                  :feedback="errors[0]"
                  v-if="domain.uuid !== '2bc5decc-cd2e-46ad-92d7-f17234fdeebd'"
                >
                  <t-datepicker
                    timepicker
                    v-model="survey.start_at"
                    :weekStart="1"
                    dateFormat="Y-m-d H:i:S"
                    userFormat="d/m/Y H:i"
                    placeholder="Clique pour sélectionner la date de début du sondage..."
                    required
                  />
                </t-input-group>
              </ValidationProvider>
              <ValidationProvider
                class="col-span-2"
                name="Fin du sondage"
                :rules="dateFieldRules"
                v-slot="{ errors }"
              >
                <t-input-group
                  label="Fin du sondage"
                  :variant="{ 'danger': errors[0] }"
                  :feedback="errors[0]"
                  v-if="domain.uuid !== '2bc5decc-cd2e-46ad-92d7-f17234fdeebd'"
                >
                  <t-datepicker
                    timepicker
                    v-model="survey.end_at"
                    :weekStart="1"
                    placeholder="Clique pour séléctionner la date de fin du sondage..."
                    dateFormat="Y-m-d H:i:S"
                    userFormat="d/m/Y H:i"
                    required
                  />
                </t-input-group>
              </ValidationProvider>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 flex gap-3 justify-between">
            <div class="flex gap-3 items-center">
              <label for="active">Actif</label>
              <t-toggle
                class="ml"
                v-model="survey.is_enabled"
              ></t-toggle>
            </div>
            <div class="flex gap-3">
              <t-button
              class="m-0"
              @click.prevent="deleteSurvey"
              variant="error"
              >Supprimer</t-button>
              <t-button
                class="m-0"
                @click="notify"
                v-if="survey.is_notified && survey.is_enabled"
              ><i class="fa-regular fa-bell"></i> Renotifier le sondage</t-button>
              <t-button
                :disabled="invalid"
                @click.prevent="updateSurvey"
              >Sauvegarder</t-button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </PageContent>
  </div>
</template>
<script>
import { http } from '@/services/api.service'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'

export default {
  name: 'app-survey',
  components: {
    PageHeader,
    PageContent
  },
  computed: {
    ...mapGetters([
      'roles',
      'user',
      'domain'
    ]),
    dateFieldRules () {
      if (this.domain.uuid === '2bc5decc-cd2e-46ad-92d7-f17234fdeebd') {
        return '' // No validation rules if the domain uuid matches
      } else {
        return 'required' // Required otherwise
      }
    }
  },
  data () {
    return {
      survey: null,
      options: [],
      noReplyAdded: false,
      usersGroups: [],
      groupsSelected: []
    }
  },
  mounted () {
    this.getSurvey()
    this.fetchGroups()
  },
  methods: {
    async fetchGroups () {
      try {
        const response = await http.get('/groups')
        this.usersGroups = response.data.map(group => ({
          value: group.uuid,
          text: group.label
        }))
      } catch (error) {
        console.error('Error fetching groups:', error)
      }
    },
    addOption () {
      if (this.options.some(val => val === '')) return
      const noReplyIndex = this.options.findIndex(option => option.option === 'Ne souhaite pas répondre')
      if (noReplyIndex > -1) {
        this.options.splice(noReplyIndex, 0, { uuid: null, option: '' })
      } else {
        this.options.push({ uuid: null, option: '' })
      }
    },
    removeOption (index) {
      if (this.options[index].option === 'Ne souhaite pas répondre') {
        this.noReplyAdded = false
      }
      this.options.splice(index, 1)
    },
    addNoReplyOption () {
      this.removeNoReplyOption()
      this.options.push({ uuid: null, option: 'Ne souhaite pas répondre' })
      this.noReplyAdded = true
    },
    removeNoReplyOption () {
      const index = this.options.findIndex(option => option.option === 'Ne souhaite pas répondre')
      if (index > -1) {
        this.options.splice(index, 1)
      }
    },
    getSurvey () {
      http.get(`/surveys/${this.$route.params.uuid}`).then(({ data }) => {
        this.options = data.options
        const noReplyOption = this.options.find(option => option.option === 'Ne souhaite pas répondre')
        this.noReplyAdded = !!noReplyOption
        data.start_at = data.start_at ? format(parseISO(data.start_at), 'yyyy-LL-dd HH:mm:ss') : null
        data.end_at = data.end_at ? format(parseISO(data.end_at), 'yyyy-LL-dd HH:mm:ss') : null
        data.is_enabled = data.is_enabled === 1
        data.is_reanswerable = data.is_reanswerable === 1
        this.groupsSelected = data.groups.map(group => group.uuid)
        this.survey = data
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à récupérer les informations concernant ce sondage',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    updateSurvey () {
      const survey = { ...this.survey, options: this.options, groupUuids: this.groupsSelected }
      http.put(`/surveys/${this.survey.uuid}`, survey).then(res => {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Le sondage a été mise à jour avec succès !',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
        this.$router.push({ path: '/app/surveys' })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour ce sondage',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    deleteSurvey () {
      this.$buefy.dialog.confirm({
        title: 'Suppression',
        message: `Êtes vous sur de vouloir supprimer ce sondage ?`,
        cancelText: 'Annuler',
        confirmText: 'Confirmer',
        type: 'is-danger',
        onConfirm: () => {
          http.delete(`/surveys/${this.survey.uuid}`).then(res => {
            this.$buefy.notification.open({
              duration: 3000,
              message: 'Le sondage a été supprimé avec succès !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
            this.$router.push({ path: '/app/surveys' })
          }).catch(err => {
            console.log(err)
            this.$buefy.notification.open({
              duration: 3000,
              message: 'Nos serveurs rencontrent des difficultés à supprimer ce sondage',
              position: 'is-bottom-left',
              type: 'is-warning',
              icon: 'server'
            })
          })
        }
      })
    },
    notify () {
      http.post(`/surveys/${this.survey.uuid}/notify`).then(res => {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Le sondage va être notifié !',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
        this.$router.push({ path: '/app/surveys' })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour ce sondage',
          position: 'is-bottom-left',
          type: 'is-warning',
          icon: 'server'
        })
      })
    }
  }
}
</script>

<style lang="scss">

</style>
