var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"title":"Modification d'un sondage"}}),(_vm.survey)?_c('PageContent',[_c('div',{staticClass:"m-auto max-w-6xl shadow sm:rounded-md"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"space-y-6 bg-white sm:p-6"},[_c('div',{staticClass:"grid grid-cols-4 gap-6"},[_c('ValidationProvider',{staticClass:"col-span-4",attrs:{"name":"Question","rules":"required|min:10|max:180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('t-input-group',{attrs:{"label":"Titre","variant":{ 'danger': errors[0] },"feedback":errors[0]}},[_c('t-input',{attrs:{"placeholder":"Question","variant":{ 'danger': errors[0] }},model:{value:(_vm.survey.question),callback:function ($$v) {_vm.$set(_vm.survey, "question", $$v)},expression:"survey.question"}})],1)]}}],null,true)}),_c('div',{staticClass:"col-span-4 flex flex-col gap-5"},[_vm._l((_vm.survey.options),function(option,i){return _c('div',{key:i},[_c('ValidationProvider',{staticClass:"flex justify-between",attrs:{"name":("Réponse " + (i + 1)),"rules":"required|min:3|max:180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('t-input-group',{staticClass:"w-full",attrs:{"label":("Réponse " + (i + 1)),"variant":{ 'danger': errors[0] },"feedback":errors[0]}},[_c('t-input',{attrs:{"placeholder":"Question","variant":{ 'danger': errors[0] }},model:{value:(_vm.survey.options[i].option),callback:function ($$v) {_vm.$set(_vm.survey.options[i], "option", $$v)},expression:"survey.options[i].option"}})],1),(_vm.survey.options.length > 2)?_c('div',{staticClass:"col-span-1 p-3 flex items-end justify-center cursor-pointer",on:{"click":function($event){return _vm.removeOption(i)}}},[_c('i',{staticClass:"fas fa-times text-red-700"})]):_vm._e()]}}],null,true)})],1)}),_c('div',{staticClass:"flex space-x-2"},[_c('t-button',{staticClass:"w-7/12",on:{"click":_vm.addOption}},[_vm._v("+ Ajouter une option")]),_c('t-button',{staticClass:"w-5/12",attrs:{"disabled":_vm.noReplyAdded},on:{"click":_vm.addNoReplyOption}},[_vm._v("+ Ajouter option 'ne souhaite pas répondre'")])],1),_c('div',{staticClass:"col-span-4"},[_c('t-input-group',{attrs:{"label":"Possibilité de changer de réponse"}},[_c('t-toggle',{model:{value:(_vm.survey.is_reanswerable),callback:function ($$v) {_vm.$set(_vm.survey, "is_reanswerable", $$v)},expression:"survey.is_reanswerable"}})],1)],1),(_vm.usersGroups && _vm.usersGroups.length)?_c('t-input-group',{attrs:{"label":"Groupes (facultatif)"}},[_c('t-rich-select',{attrs:{"multiple":"","close-on-select":false,"options":_vm.usersGroups,"placeholder":"Sélectionner des groupes"},model:{value:(_vm.groupsSelected),callback:function ($$v) {_vm.groupsSelected=$$v},expression:"groupsSelected"}})],1):_vm._e()],2),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"name":"Début du sondage","rules":_vm.dateFieldRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.domain.uuid !== '2bc5decc-cd2e-46ad-92d7-f17234fdeebd')?_c('t-input-group',{attrs:{"label":"Début du sondage","variant":{ 'danger': errors[0] },"feedback":errors[0]}},[_c('t-datepicker',{attrs:{"timepicker":"","weekStart":1,"dateFormat":"Y-m-d H:i:S","userFormat":"d/m/Y H:i","placeholder":"Clique pour sélectionner la date de début du sondage...","required":""},model:{value:(_vm.survey.start_at),callback:function ($$v) {_vm.$set(_vm.survey, "start_at", $$v)},expression:"survey.start_at"}})],1):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"name":"Fin du sondage","rules":_vm.dateFieldRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.domain.uuid !== '2bc5decc-cd2e-46ad-92d7-f17234fdeebd')?_c('t-input-group',{attrs:{"label":"Fin du sondage","variant":{ 'danger': errors[0] },"feedback":errors[0]}},[_c('t-datepicker',{attrs:{"timepicker":"","weekStart":1,"placeholder":"Clique pour séléctionner la date de fin du sondage...","dateFormat":"Y-m-d H:i:S","userFormat":"d/m/Y H:i","required":""},model:{value:(_vm.survey.end_at),callback:function ($$v) {_vm.$set(_vm.survey, "end_at", $$v)},expression:"survey.end_at"}})],1):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"bg-gray-50 px-4 py-3 flex gap-3 justify-between"},[_c('div',{staticClass:"flex gap-3 items-center"},[_c('label',{attrs:{"for":"active"}},[_vm._v("Actif")]),_c('t-toggle',{staticClass:"ml",model:{value:(_vm.survey.is_enabled),callback:function ($$v) {_vm.$set(_vm.survey, "is_enabled", $$v)},expression:"survey.is_enabled"}})],1),_c('div',{staticClass:"flex gap-3"},[_c('t-button',{staticClass:"m-0",attrs:{"variant":"error"},on:{"click":function($event){$event.preventDefault();return _vm.deleteSurvey($event)}}},[_vm._v("Supprimer")]),(_vm.survey.is_notified && _vm.survey.is_enabled)?_c('t-button',{staticClass:"m-0",on:{"click":_vm.notify}},[_c('i',{staticClass:"fa-regular fa-bell"}),_vm._v(" Renotifier le sondage")]):_vm._e(),_c('t-button',{attrs:{"disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.updateSurvey($event)}}},[_vm._v("Sauvegarder")])],1)])]}}],null,false,2087112053)})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }